import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import Hero17 from '../components/hero17'
import Features24 from '../components/features24'
import Features16 from '../components/features16'
import Steps2 from '../components/steps2'
import Features23 from '../components/features23'
import Features231 from '../components/features231'
import CTA26 from '../components/cta26'
import Footer1 from '../components/footer1'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Exalted Outlandish Buffalo</title>
        <meta property="og:title" content="Exalted Outlandish Buffalo" />
      </Helmet>
      <Navbar8
        link1={
          <Fragment>
            <span className="home-text100 thq-body-small thq-link">Home</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text101 thq-body-small thq-link">
              About Us
            </span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text102 thq-body-small thq-link">
              Contact
            </span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="home-text103 thq-body-large">Home</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="home-text104 thq-body-large">Products</span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="home-text105 thq-body-large">About Us</span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="home-text106 thq-body-large">Contact</span>
          </Fragment>
        }
        link11={
          <Fragment>
            <span className="home-text107 thq-body-small thq-link">
              Process
            </span>
          </Fragment>
        }
        link12={
          <Fragment>
            <span className="home-text108 thq-body-small thq-link">Values</span>
          </Fragment>
        }
        link13={
          <Fragment>
            <span className="home-text109 thq-body-small thq-link">
              Offering
            </span>
          </Fragment>
        }
        link111={
          <Fragment>
            <span className="home-text110 thq-body-small thq-link">
              Technology
            </span>
          </Fragment>
        }
        link112={
          <Fragment>
            <span className="home-text111 thq-body-small thq-link">Value</span>
          </Fragment>
        }
        link113={
          <Fragment>
            <span className="home-text112 thq-body-small thq-link">
              Process
            </span>
          </Fragment>
        }
        link121={
          <Fragment>
            <span className="home-text113 thq-body-small thq-link">Values</span>
          </Fragment>
        }
        link131={
          <Fragment>
            <span className="home-text114 thq-body-small thq-link">
              Offering
            </span>
          </Fragment>
        }
        logoSrc="/finallogo-1500h.png"
        link1111={
          <Fragment>
            <span className="home-text115 thq-body-small thq-link">
              <span className="home-text116">Contact Us</span>
              <br></br>
            </span>
          </Fragment>
        }
        link1112={
          <Fragment>
            <span className="home-text118 thq-body-small thq-link">
              Technology
            </span>
          </Fragment>
        }
        link1121={
          <Fragment>
            <span className="home-text119 thq-body-small thq-link">Value</span>
          </Fragment>
        }
        link11111={
          <Fragment>
            <span className="home-text120 thq-body-small thq-link">Brands</span>
          </Fragment>
        }
        link1Url1="#Steps2"
        link1Url2="#Features16"
        link1Url3="#Features24"
        link1Url11="#Features231"
        link1Url12="#Features23"
        link1Url112="#CTA26"
        page1Description={
          <Fragment>
            <span className="home-text121 thq-body-small">
              Welcome to Omnitrux - Your Retail Partner
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="home-text122 thq-body-small">
              Explore Our Partnered Products
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="home-text123 thq-body-small">
              Learn More About Omnitrux
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="home-text124 thq-body-small">
              Get in Touch with Us
            </span>
          </Fragment>
        }
      ></Navbar8>
      <Hero17
        content1={
          <Fragment>
            <span className="home-text125 thq-body-large">
              Partner with us to reach more customers and grow your brand
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text126 thq-heading-1">
              Expand Your Retail Network with Omnitrux
            </span>
          </Fragment>
        }
      ></Hero17>
      <Features24
        text={
          <Fragment>
            <span className="home-text127 thq-heading-1">Our Offering</span>
          </Fragment>
        }
        features24Id="Features24"
        feature1Title={
          <Fragment>
            <span className="home-text128 thq-heading-2">
              Partnership with Small Brands
            </span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text129 thq-heading-2">
              Retail Network Expansion
            </span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text130 thq-heading-2">
              Supply Chain Management
            </span>
          </Fragment>
        }
      ></Features24>
      <Features16
        heading1={
          <Fragment>
            <span className="home-text131 thq-heading-2">Values</span>
          </Fragment>
        }
        features16Id="Features16"
        feature1Title={
          <Fragment>
            <span className="home-text132 thq-heading-3">Think big</span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text133 thq-heading-3">
              Fair, Fast &amp; Founder friendly
            </span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text134 thq-heading-3">Customer delight</span>
          </Fragment>
        }
        feature1Title1={
          <Fragment>
            <span className="home-text135 thq-heading-3">
              Caring Meritocracy
            </span>
          </Fragment>
        }
        feature2Title1={
          <Fragment>
            <span className="home-text136 thq-heading-3">Frugality</span>
          </Fragment>
        }
        feature1Description={
          <Fragment>
            <span className="home-text137 thq-body-small">
              Think 100x. Use technology for non-linear growth. Failure and
              experimentation are essential for growth
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text138 thq-body-small">
              Be partner of choice, who is ethical, transparent and
              collaborative
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text139 thq-body-small">
              Customer love matters. Each of our brands needs to be in the top
              decile of customer experience
            </span>
          </Fragment>
        }
        feature1Description1={
          <Fragment>
            <span className="home-text140 thq-body-small">
              Attract and grow the best talent. Create an inclusive culture
              where the best ideas win and you have the obligation to engage and
              dissent
            </span>
          </Fragment>
        }
        feature2Description1={
          <Fragment>
            <span className="home-text141 thq-body-small">
              We are frugal with capital and use innovation to grow our brands
              in a sustainable manner
            </span>
          </Fragment>
        }
      ></Features16>
      <Steps2
        steps2Id="Steps2"
        step1Title={
          <Fragment>
            <span className="home-text142 thq-heading-2">
              Getting to know your brand
            </span>
          </Fragment>
        }
        step2Title={
          <Fragment>
            <span className="home-text143 thq-heading-2">
              Valuation and Commercial Construct
            </span>
          </Fragment>
        }
        step3Title={
          <Fragment>
            <span className="home-text144 thq-heading-2">
              Due Diligence and Deal Closure
            </span>
          </Fragment>
        }
        step4Title={
          <Fragment>
            <span className="home-text145 thq-heading-2">
              Accelerate your Brand
            </span>
          </Fragment>
        }
        step1Description={
          <Fragment>
            <span className="home-text146 thq-body-small">
              1-2 DETAILED MEETINGS
            </span>
          </Fragment>
        }
        step2Description={
          <Fragment>
            <span className="home-text147 thq-body-small">
              <span>48 HOURS</span>
              <br></br>
            </span>
          </Fragment>
        }
        step3Description={
          <Fragment>
            <span className="home-text150 thq-body-small">4 WEEKS</span>
          </Fragment>
        }
        step4Description={
          <Fragment>
            <span className="home-text151 thq-body-small">10X AND BEYOND</span>
          </Fragment>
        }
      ></Steps2>
      <Features23
        heading1={
          <Fragment>
            <span className="home-text152 thq-heading-2">
              How do we add value?
            </span>
          </Fragment>
        }
        features23Id="Features23"
        feature1Title={
          <Fragment>
            <span className="home-text153 thq-heading-2">Technology</span>
          </Fragment>
        }
        feature1Title2={
          <Fragment>
            <span className="home-text154 thq-heading-2">Go Global</span>
          </Fragment>
        }
        feature1Title3={
          <Fragment>
            <span className="home-text155 thq-heading-2">
              Digital brand building
            </span>
          </Fragment>
        }
        feature1Title4={
          <Fragment>
            <span className="home-text156 thq-heading-2">
              Data driven marketing
            </span>
          </Fragment>
        }
        feature1Title5={
          <Fragment>
            <span className="home-text157 thq-heading-2">
              Optimized operations
            </span>
          </Fragment>
        }
        feature1Title6={
          <Fragment>
            <span className="home-text158 thq-heading-2">
              Channel Expansion
            </span>
          </Fragment>
        }
        feature1Description={
          <Fragment>
            <span className="home-text159 thq-body-small">
              We leverage data to integrate sales, order and supply platforms
              across brands and improve CX of our brand partners
            </span>
          </Fragment>
        }
        feature1Description2={
          <Fragment>
            <span className="home-text160 thq-body-small">
              Leverage our expertise and data to take Indian brands global by
              developing relevant products and packaging and optimizing global
              supply chain
            </span>
          </Fragment>
        }
        feature1Description3={
          <Fragment>
            <span className="home-text161 thq-body-small">
              Leverage social media, content, and influencer networks to build
              digital-first brands
            </span>
          </Fragment>
        }
        feature1Description4={
          <Fragment>
            <span className="home-text162 thq-body-small">
              Leverage big data &amp; AI to data to forecast demand and optimize
              advertising, promotions, and pricing
            </span>
          </Fragment>
        }
        feature1Description5={
          <Fragment>
            <span className="home-text163 thq-body-small">
              Expand and optimize distribution to improve availability
            </span>
          </Fragment>
        }
        feature1Description6={
          <Fragment>
            <span className="home-text164 thq-body-small">
              Growth hack across large and vertical ecommerce platforms and help
              scale D2C business
            </span>
          </Fragment>
        }
      ></Features23>
      <Features231
        content1={
          <Fragment>
            <span className="home-text165 thq-body-small">
              We leverage technology to make better decisions, grow brands, and
              enhance the customer experience.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text166 thq-heading-2">Technology</span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="home-text167 thq-heading-2">
              Brand Acquisition
            </span>
          </Fragment>
        }
        features231Id="Features231"
        feature1Title1={
          <Fragment>
            <span className="home-text168 thq-heading-2">
              <span>Customer</span>
              <br></br>
              <span>Experience</span>
              <br></br>
            </span>
          </Fragment>
        }
        feature1Title2={
          <Fragment>
            <span className="home-text173 thq-heading-2">
              <span>Brand</span>
              <br></br>
              <span> Growth</span>
            </span>
          </Fragment>
        }
        feature1Description={
          <Fragment>
            <span className="home-text177 thq-body-small">
              <span>
                We use Machine Learning and Big Data to identify right brands by
                analysing millions of data points across the internet
              </span>
              <br></br>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        feature1Description1={
          <Fragment>
            <span className="home-text182 thq-body-small">
              <span>
                We provide a technology platform that ensures quick channel
                expansion, efficient operations, and best-in-class customer
                experience
              </span>
              <br className="home-text184"></br>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        feature1Description2={
          <Fragment>
            <span className="home-text187 thq-body-small">
              <span>
                We provide deep insight and tech capabilities to turbo charge
                brand growth, identify right trends, optimise content (SEO),
                manage inventory, and improve profitability.
              </span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
      ></Features231>
      <CTA26
        cTA26Id="CTA26"
        content1={
          <Fragment>
            <span className="home-text191 thq-body-large">
              Please reach out to us at omnitrux.global@gmail.com
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text192 thq-heading-2">
              Ready to expand your retail network?
            </span>
          </Fragment>
        }
      ></CTA26>
      <Footer1
        link1={
          <Fragment>
            <span className="home-text193 thq-body-small">About Us</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text194 thq-body-small">Partnership</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text195 thq-body-small">Contact Us</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text196 thq-body-small">Privacy Policy</span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="home-text197 thq-body-small">
              Terms of Service
            </span>
          </Fragment>
        }
        link6={
          <Fragment>
            <span className="home-text198 thq-body-small">Cookies Policy</span>
          </Fragment>
        }
        link7={
          <Fragment>
            <span className="home-text199 thq-body-small">Accessibility</span>
          </Fragment>
        }
        link8={
          <Fragment>
            <span className="home-text200 thq-body-small">GDPR Compliance</span>
          </Fragment>
        }
        link9={
          <Fragment>
            <span className="home-text201 thq-body-small">Disclaimer</span>
          </Fragment>
        }
        link10={
          <Fragment>
            <span className="home-text202 thq-body-small">Sitemap</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text203 thq-body-small">Subscribe</span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="home-text204 thq-body-small">
              By subscribing you agree to with our Privacy Policy and provide
              consent to receive updates from our company.
            </span>
          </Fragment>
        }
        content3={
          <Fragment>
            <span className="home-text205 thq-body-small">© 2024 Omnitrux</span>
          </Fragment>
        }
        termsLink={
          <Fragment>
            <span className="home-text206 thq-body-small">
              /terms-of-service
            </span>
          </Fragment>
        }
        cookiesLink={
          <Fragment>
            <span className="home-text207 thq-body-small">/cookies-policy</span>
          </Fragment>
        }
        privacyLink={
          <Fragment>
            <span className="home-text208 thq-body-small">/privacy-policy</span>
          </Fragment>
        }
        column1Title={
          <Fragment>
            <span className="home-text209 thq-body-large">Company</span>
          </Fragment>
        }
        column2Title={
          <Fragment>
            <span className="home-text210 thq-body-large">Legal</span>
          </Fragment>
        }
        socialLinkTitleCategory={
          <Fragment>
            <span className="home-text211 thq-body-large">Connect with us</span>
          </Fragment>
        }
      ></Footer1>
    </div>
  )
}

export default Home
