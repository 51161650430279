import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './features231.css'

const Features231 = (props) => {
  return (
    <div
      id={props.features231Id}
      className="features231-layout349 thq-section-padding"
    >
      <div className="features231-max-width thq-section-max-width">
        <div className="features231-container1">
          <h2>
            {props.heading1 ?? (
              <Fragment>
                <h2 className="features231-text23 thq-heading-2">Technology</h2>
              </Fragment>
            )}
          </h2>
          <span>
            {props.content1 ?? (
              <Fragment>
                <span className="features231-text39 thq-body-small">
                  We leverage technology to make better decisions, grow brands,
                  and enhance the customer experience.
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="thq-grid-3">
          {[{
            title: "Brand Acquisition\nthrough Data",
            description: "We use Machine Learning and Big Data to identify right brands by analysing millions of data points across the internet",
            imageSrc: props.imageSrc,
            imageAlt: props.imageAlt
          },
          {
            title: "Brand\nGrowth",
            description: "We provide deep insight and tech capabilities to turbo charge brand growth, identify right trends, optimise content (SEO), manage inventory, and improve profitability.",
            imageSrc: props.imageSrc5,
            imageAlt: props.imageAlt5
          },
          {
            title: "Customer Experience",
            description: "We provide a technology platform that ensures quick channel expansion, efficient operations, and best-in-class customer experience",
            imageSrc: props.imageSrc6,
            imageAlt: props.imageAlt6
          }].map((item, index) => (
            <div key={index} className="features231-container thq-card">
              <h2 className="features231-text thq-heading-2">
                {item.title.split('\n').map((line, i) => (
                  <React.Fragment key={i}>
                    {line}
                    {i === 0 && <br />}
                  </React.Fragment>
                ))}
              </h2>
              <span className="features231-description thq-body-small">
                {item.description}
              </span>
              <img
                alt={item.imageAlt}
                src={item.imageSrc}
                className="features231-image"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

Features231.defaultProps = {
  imageAlt: 'image',
  imageSrc: '/1-700h.png',
  imageSrc5: '/2-700h.png',
  imageAlt5: 'image',
  imageAlt6: 'image',
  features231Id: '',
  imageSrc6: '/3-700h.png',
}

Features231.propTypes = {
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  imageSrc5: PropTypes.string,
  imageAlt5: PropTypes.string,
  imageAlt6: PropTypes.string,
  features231Id: PropTypes.string,
  imageSrc6: PropTypes.string,
  heading1: PropTypes.element,
  content1: PropTypes.element,
}

export default Features231
