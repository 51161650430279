import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './features23.css'

const Features23 = (props) => {
  return (
    <div
      id={props.features23Id}
      className="features23-layout349 thq-section-padding"
    >
      <div className="features23-max-width thq-section-max-width">
        <div className="features23-container10">
          <h2>
            {props.heading1 ?? (
              <Fragment>
                <h2 className="features23-text23 thq-heading-2">
                  How do we add value?
                </h2>
              </Fragment>
            )}
          </h2>
        </div>
        <div className="thq-grid-3">
          <div className="features23-container12 thq-card">
            <div className="features23-container13 thq-card">
              <img
                alt={props.imageAlt3}
                src={props.imageSrc3}
                className="features23-image1"
              />
            </div>
            <h2>
              {props.feature1Title ?? (
                <Fragment>
                  <h2 className="features23-text27 thq-heading-2">
                    Technology
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.feature1Description ?? (
                <Fragment>
                  <span className="features23-text34 thq-body-small">
                    We leverage data to integrate sales, order and supply
                    platforms across brands and improve CX of our brand partners
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features23-container14 thq-card">
            <div className="features23-container15 thq-card">
              <img
                alt={props.imageAlt35}
                src={props.imageSrc35}
                className="features23-image2"
              />
            </div>
            <h2>
              {props.feature1Title6 ?? (
                <Fragment>
                  <h2 className="features23-text25 thq-heading-2">
                    Channel Expansion
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.feature1Description6 ?? (
                <Fragment>
                  <span className="features23-text26 thq-body-small">
                    Growth hack across large and vertical ecommerce platforms
                    and help scale D2C business
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features23-container16 thq-card">
            <div className="features23-container17 thq-card">
              <img
                alt={props.imageAlt34}
                src={props.imageSrc34}
                className="features23-image3"
              />
            </div>
            <h2>
              {props.feature1Title5 ?? (
                <Fragment>
                  <h2 className="features23-text33 thq-heading-2">
                    Optimized operations
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.feature1Description5 ?? (
                <Fragment>
                  <span className="features23-text35 thq-body-small">
                    Expand and optimize distribution to improve availability
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features23-container18 thq-card">
            <div className="features23-container19 thq-card">
              <img
                alt={props.imageAlt33}
                src={props.imageSrc33}
                className="features23-image4"
              />
            </div>
            <h2>
              {props.feature1Title4 ?? (
                <Fragment>
                  <h2 className="features23-text31 thq-heading-2">
                    Data driven marketing
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.feature1Description4 ?? (
                <Fragment>
                  <span className="features23-text29 thq-body-small">
                    Leverage big data &amp; AI to data to forecast demand and
                    optimize advertising, promotions, and pricing
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features23-container20 thq-card">
            <div className="features23-container21 thq-card">
              <img
                alt={props.imageAlt32}
                src={props.imageSrc32}
                className="features23-image5"
              />
            </div>
            <h2>
              {props.feature1Title3 ?? (
                <Fragment>
                  <h2 className="features23-text30 thq-heading-2">
                    Digital brand building
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.feature1Description3 ?? (
                <Fragment>
                  <span className="features23-text28 thq-body-small">
                    Leverage social media, content, and influencer networks to
                    build digital-first brands
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features23-container22 thq-card">
            <div className="features23-container23 thq-card">
              <img
                alt={props.imageAlt31}
                src={props.imageSrc31}
                className="features23-image6"
              />
            </div>
            <h2>
              {props.feature1Title2 ?? (
                <Fragment>
                  <h2 className="features23-text32 thq-heading-2">Go Global</h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.feature1Description2 ?? (
                <Fragment>
                  <span className="features23-text24 thq-body-small">
                    Leverage our expertise and data to take Indian brands global
                    by developing relevant products and packaging and optimizing
                    global supply chain
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

Features23.defaultProps = {
  imageAlt35: 'image',
  heading1: undefined,
  feature1Description2: undefined,
  imageAlt31: 'image',
  imageSrc31: '/tick-200h.png',
  imageSrc35: '/tick-200h.png',
  imageSrc3: '/tick-200h.png',
  feature1Title6: undefined,
  features23Id: '',
  feature1Description6: undefined,
  imageSrc34: '/tick-200h.png',
  feature1Title: undefined,
  feature1Description3: undefined,
  imageAlt3: 'image',
  imageAlt32: 'image',
  imageSrc32: '/tick-200h.png',
  feature1Description4: undefined,
  feature1Title3: undefined,
  feature1Title4: undefined,
  imageAlt34: 'image',
  imageSrc33: '/tick-200h.png',
  feature1Title2: undefined,
  feature1Title5: undefined,
  feature1Description: undefined,
  imageAlt33: 'image',
  feature1Description5: undefined,
}

Features23.propTypes = {
  imageAlt35: PropTypes.string,
  heading1: PropTypes.element,
  feature1Description2: PropTypes.element,
  imageAlt31: PropTypes.string,
  imageSrc31: PropTypes.string,
  imageSrc35: PropTypes.string,
  imageSrc3: PropTypes.string,
  feature1Title6: PropTypes.element,
  features23Id: PropTypes.string,
  feature1Description6: PropTypes.element,
  imageSrc34: PropTypes.string,
  feature1Title: PropTypes.element,
  feature1Description3: PropTypes.element,
  imageAlt3: PropTypes.string,
  imageAlt32: PropTypes.string,
  imageSrc32: PropTypes.string,
  feature1Description4: PropTypes.element,
  feature1Title3: PropTypes.element,
  feature1Title4: PropTypes.element,
  imageAlt34: PropTypes.string,
  imageSrc33: PropTypes.string,
  feature1Title2: PropTypes.element,
  feature1Title5: PropTypes.element,
  feature1Description: PropTypes.element,
  imageAlt33: PropTypes.string,
  feature1Description5: PropTypes.element,
}

export default Features23
