import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './footer1.css'

const Footer1 = (props) => {
  return (
    <footer className="footer1-footer1 thq-section-padding">
      <div className="footer1-max-width thq-section-max-width">
        <div className="footer1-content">
          <div className="footer1-newsletter">
            <span className="footer1-content1 thq-body-small">
              Subscribe to our newsletter for the latest updates on new features
              and product releases.
            </span>
            <div className="footer1-actions">
              <div className="footer1-form">
                <div className="footer1-container">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="footer1-text-input thq-input"
                  />
                </div>
                <button className="thq-button-outline footer1-button">
                  <span>
                    {props.action1 ?? (
                      <Fragment>
                        <span className="footer1-text21 thq-body-small">
                          Subscribe
                        </span>
                      </Fragment>
                    )}
                  </span>
                </button>
              </div>
              <span>
                {props.content2 ?? (
                  <Fragment>
                    <span className="footer1-text14 thq-body-small">
                      By subscribing you agree to with our Privacy Policy and
                      provide consent to receive updates from our company.
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
          <div className="footer1-links">
            <div className="footer1-column1">
              <strong>
                {props.column1Title ?? (
                  <Fragment>
                    <strong className="footer1-text10 thq-body-large">
                      Company
                    </strong>
                  </Fragment>
                )}
              </strong>
              <div className="footer1-footer-links1">
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {props.link1 ?? (
                    <Fragment>
                      <span className="footer1-text23 thq-body-small">
                        About Us
                      </span>
                    </Fragment>
                  )}
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {props.link2 ?? (
                    <Fragment>
                      <span className="footer1-text19 thq-body-small">
                        Partnership
                      </span>
                    </Fragment>
                  )}
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {props.link3 ?? (
                    <Fragment>
                      <span className="footer1-text26 thq-body-small">
                        Contact Us
                      </span>
                    </Fragment>
                  )}
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {props.link4 ?? (
                    <Fragment>
                      <span className="footer1-text25 thq-body-small">
                        Privacy Policy
                      </span>
                    </Fragment>
                  )}
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {props.link5 ?? (
                    <Fragment>
                      <span className="footer1-text12 thq-body-small">
                        Terms of Service
                      </span>
                    </Fragment>
                  )}
                </a>
              </div>
            </div>
            <div className="footer1-column2">
              <strong>
                {props.column2Title ?? (
                  <Fragment>
                    <strong className="footer1-text27 thq-body-large">
                      Legal
                    </strong>
                  </Fragment>
                )}
              </strong>
              <div className="footer1-footer-links2">
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {props.link6 ?? (
                    <Fragment>
                      <span className="footer1-text15 thq-body-small">
                        Cookies Policy
                      </span>
                    </Fragment>
                  )}
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {props.link7 ?? (
                    <Fragment>
                      <span className="footer1-text22 thq-body-small">
                        Accessibility
                      </span>
                    </Fragment>
                  )}
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {props.link8 ?? (
                    <Fragment>
                      <span className="footer1-text16 thq-body-small">
                        GDPR Compliance
                      </span>
                    </Fragment>
                  )}
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {props.link9 ?? (
                    <Fragment>
                      <span className="footer1-text20 thq-body-small">
                        Disclaimer
                      </span>
                    </Fragment>
                  )}
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {props.link10 ?? (
                    <Fragment>
                      <span className="footer1-text17 thq-body-small">
                        Sitemap
                      </span>
                    </Fragment>
                  )}
                </a>
              </div>
            </div>
            <div className="footer1-column3">
              <strong>
                {props.socialLinkTitleCategory ?? (
                  <Fragment>
                    <strong className="footer1-text13 thq-body-large">
                      Connect with us
                    </strong>
                  </Fragment>
                )}
              </strong>
              <div className="footer1-social-links">
                <div className="footer1-link14">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="thq-icon-small"
                  >
                    <path d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"></path>
                  </svg>
                  <span className="thq-body-small">Facebook</span>
                </div>
                <div className="footer1-link15">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="thq-icon-small"
                  >
                    <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                  </svg>
                  <span className="thq-body-small">Instagram</span>
                </div>
                <div className="footer1-link16">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="thq-icon-small"
                  >
                    <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                  </svg>
                  <span className="thq-body-small">LinkedIn</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer1-credits">
          <div className="thq-divider-horizontal"></div>
          <div className="footer1-row">
            <span>
              {props.content3 ?? (
                <Fragment>
                  <span className="footer1-text24 thq-body-small">
                    © 2024 Omnitrux
                  </span>
                </Fragment>
              )}
            </span>
            <div className="footer1-footer-links3">
              <span>
                {props.privacyLink ?? (
                  <Fragment>
                    <span className="footer1-text28 thq-body-small">
                      /privacy-policy
                    </span>
                  </Fragment>
                )}
              </span>
              <span>
                {props.termsLink ?? (
                  <Fragment>
                    <span className="footer1-text11 thq-body-small">
                      /terms-of-service
                    </span>
                  </Fragment>
                )}
              </span>
              <span>
                {props.cookiesLink ?? (
                  <Fragment>
                    <span className="footer1-text18 thq-body-small">
                      /cookies-policy
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer1.defaultProps = {
  column1Title: undefined,
  termsLink: undefined,
  link5: undefined,
  socialLinkTitleCategory: undefined,
  content2: undefined,
  link6: undefined,
  link8: undefined,
  link10: undefined,
  cookiesLink: undefined,
  link2: undefined,
  link9: undefined,
  action1: undefined,
  link7: undefined,
  link1: undefined,
  content3: undefined,
  link4: undefined,
  link3: undefined,
  column2Title: undefined,
  privacyLink: undefined,
}

Footer1.propTypes = {
  column1Title: PropTypes.element,
  termsLink: PropTypes.element,
  link5: PropTypes.element,
  socialLinkTitleCategory: PropTypes.element,
  content2: PropTypes.element,
  link6: PropTypes.element,
  link8: PropTypes.element,
  link10: PropTypes.element,
  cookiesLink: PropTypes.element,
  link2: PropTypes.element,
  link9: PropTypes.element,
  action1: PropTypes.element,
  link7: PropTypes.element,
  link1: PropTypes.element,
  content3: PropTypes.element,
  link4: PropTypes.element,
  link3: PropTypes.element,
  column2Title: PropTypes.element,
  privacyLink: PropTypes.element,
}

export default Footer1
