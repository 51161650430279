import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './steps2.css'

const Steps2 = (props) => {
  return (
    <div id={props.steps2Id} className="steps2-container1 thq-section-padding">
      <div className="steps2-max-width thq-section-max-width">
        <div className="steps2-container2 thq-grid-2">
          <div className="steps2-section-header">
            <h2 className="steps2-text10 thq-heading-2">
              How we work with brand founders?
            </h2>
            <p className="steps2-text11 thq-body-large">
              We are curious about you and your brand. We invest into
              understanding your brand’s journey both financially as well as
              qualitatively.
            </p>
          </div>
          <div className="steps2-container3">
            <div className="steps2-container4 thq-card">
              <h2>
                {props.step1Title ?? (
                  <Fragment>
                    <h2 className="steps2-text24 thq-heading-2">
                      Getting to know your brand
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.step1Description ?? (
                  <Fragment>
                    <span className="steps2-text31 thq-body-small">
                      1-2 DETAILED MEETINGS
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text14 thq-heading-3">01</label>
            </div>
            <div className="steps2-container5 thq-card">
              <h2>
                {props.step2Title ?? (
                  <Fragment>
                    <h2 className="steps2-text25 thq-heading-2">
                      Valuation and Commercial Construct
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.step2Description ?? (
                  <Fragment>
                    <span className="steps2-text26 thq-body-small">
                      <span>48 HOURS</span>
                      <br></br>
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text17 thq-heading-3">02</label>
            </div>
            <div className="steps2-container6 thq-card">
              <h2>
                {props.step3Title ?? (
                  <Fragment>
                    <h2 className="steps2-text32 thq-heading-2">
                      Due Diligence and Deal Closure
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.step3Description ?? (
                  <Fragment>
                    <span className="steps2-text33 thq-body-small">
                      4 WEEKS
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text20 thq-heading-3">03</label>
            </div>
            <div className="steps2-container7 thq-card">
              <h2>
                {props.step4Title ?? (
                  <Fragment>
                    <h2 className="steps2-text30 thq-heading-2">
                      Accelerate your Brand
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.step4Description ?? (
                  <Fragment>
                    <span className="steps2-text29 thq-body-small">
                      10X AND BEYOND
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text23 thq-heading-3">04</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps2.defaultProps = {
  steps2Id: '',
  step1Title: undefined,
  step2Title: undefined,
  step2Description: undefined,
  step4Description: undefined,
  step4Title: undefined,
  step1Description: undefined,
  step3Title: undefined,
  step3Description: undefined,
}

Steps2.propTypes = {
  steps2Id: PropTypes.string,
  step1Title: PropTypes.element,
  step2Title: PropTypes.element,
  step2Description: PropTypes.element,
  step4Description: PropTypes.element,
  step4Title: PropTypes.element,
  step1Description: PropTypes.element,
  step3Title: PropTypes.element,
  step3Description: PropTypes.element,
}

export default Steps2
