import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './features16.css'

const Features16 = (props) => {
  return (
    <div
      id={props.features16Id}
      className="features16-layout300 thq-section-padding"
    >
      <div className="features16-max-width thq-section-max-width">
        <div className="features16-section-title">
          <div className="features16-content1">
            <h2>
              {props.heading1 ?? (
                <Fragment>
                  <h2 className="features16-text14 thq-heading-2">Values</h2>
                </Fragment>
              )}
            </h2>
          </div>
        </div>
        <div className="features16-content2">
          <div className="features16-row1 thq-flex-row">
            <div className="features16-feature11">
              <div className="features16-content3">
                <img
                  alt={props.imageAlt1}
                  src={props.imageSrc1}
                  className="features16-image1"
                />
                <h3>
                  {props.feature1Title ?? (
                    <Fragment>
                      <h3 className="features16-text20 thq-heading-3">
                        Think big
                      </h3>
                    </Fragment>
                  )}
                </h3>
                <span>
                  {props.feature1Description ?? (
                    <Fragment>
                      <span className="features16-text21 thq-body-small">
                        Think 100x. Use technology for non-linear growth.
                        Failure and experimentation are essential for growth
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
            <div className="features16-feature21">
              <div className="features16-content4">
                <img
                  alt={props.imageAlt2}
                  src={props.imageSrc2}
                  className="features16-image2"
                />
                <h3>
                  {props.feature2Title ?? (
                    <Fragment>
                      <h3 className="features16-text19 thq-heading-3">
                        Fair, Fast &amp; Founder friendly
                      </h3>
                    </Fragment>
                  )}
                </h3>
                <span>
                  {props.feature2Description ?? (
                    <Fragment>
                      <span className="features16-text15 thq-body-small">
                        Be partner of choice, who is ethical, transparent and
                        collaborative
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
            <div className="features16-feature3">
              <div className="features16-content5">
                <img
                  alt={props.imageAlt3}
                  src={props.imageSrc3}
                  className="features16-image3"
                />
                <h3>
                  {props.feature3Title ?? (
                    <Fragment>
                      <h3 className="features16-text17 thq-heading-3">
                        Customer delight
                      </h3>
                    </Fragment>
                  )}
                </h3>
                <span>
                  {props.feature3Description ?? (
                    <Fragment>
                      <span className="features16-text13 thq-body-small">
                        Customer love matters. Each of our brands needs to be in
                        the top decile of customer experience
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="features16-content6">
          <div className="features16-row2 thq-flex-row">
            <div className="features16-feature12">
              <div className="features16-content7">
                <img
                  alt={props.imageAlt4}
                  src={props.imageSrc4}
                  className="features16-image4"
                />
                <h3>
                  {props.feature1Title1 ?? (
                    <Fragment>
                      <h3 className="features16-text11 thq-heading-3">
                        Caring Meritocracy
                      </h3>
                    </Fragment>
                  )}
                </h3>
                <span>
                  {props.feature1Description1 ?? (
                    <Fragment>
                      <span className="features16-text18 thq-body-small">
                        Attract and grow the best talent. Create an inclusive
                        culture where the best ideas win and you have the
                        obligation to engage and dissent
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
            <div className="features16-feature22">
              <div className="features16-content8">
                <img
                  alt={props.imageAlt5}
                  src={props.imageSrc5}
                  className="features16-image5"
                />
                <h3>
                  {props.feature2Title1 ?? (
                    <Fragment>
                      <h3 className="features16-text16 thq-heading-3">
                        Frugality
                      </h3>
                    </Fragment>
                  )}
                </h3>
                <span>
                  {props.feature2Description1 ?? (
                    <Fragment>
                      <span className="features16-text12 thq-body-small">
                        We are frugal with capital and use innovation to grow
                        our brands in a sustainable manner
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Features16.defaultProps = {
  feature1Title1: undefined,
  features16Id: '',
  feature2Description1: undefined,
  imageSrc3: '/delight-200h.png',
  feature3Description: undefined,
  imageSrc: '72bf5901-0789-4d03-99e6-7998cb9072ca',
  imageAlt4: 'image',
  imageAlt1: 'image',
  heading1: undefined,
  imageAlt3: 'image',
  imageAlt2: 'image',
  imageSrc5: '/frugality-200h.png',
  imageSrc4: '/caring-200h.png',
  feature2Description: undefined,
  feature2Title1: undefined,
  imageSrc1: '/think-200h.png',
  feature3Title: undefined,
  feature1Description1: undefined,
  feature2Title: undefined,
  feature1Title: undefined,
  feature1Description: undefined,
  imageSrc2: '/fast-200h.png',
  imageAlt5: 'image',
}

Features16.propTypes = {
  feature1Title1: PropTypes.element,
  features16Id: PropTypes.string,
  feature2Description1: PropTypes.element,
  imageSrc3: PropTypes.string,
  feature3Description: PropTypes.element,
  imageSrc: PropTypes.string,
  imageAlt4: PropTypes.string,
  imageAlt1: PropTypes.string,
  heading1: PropTypes.element,
  imageAlt3: PropTypes.string,
  imageAlt2: PropTypes.string,
  imageSrc5: PropTypes.string,
  imageSrc4: PropTypes.string,
  feature2Description: PropTypes.element,
  feature2Title1: PropTypes.element,
  imageSrc1: PropTypes.string,
  feature3Title: PropTypes.element,
  feature1Description1: PropTypes.element,
  feature2Title: PropTypes.element,
  feature1Title: PropTypes.element,
  feature1Description: PropTypes.element,
  imageSrc2: PropTypes.string,
  imageAlt5: PropTypes.string,
}

export default Features16
