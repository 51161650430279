import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './features24.css'

const Features24 = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div
      id={props.features24Id}
      className="features24-container1 thq-section-padding"
    >
      <span>
        {props.text ?? (
          <Fragment>
            <span className="features24-text2 thq-heading-1">Our Offering</span>
          </Fragment>
        )}
      </span>
      <div className="features24-tabs-menu">
        <div
          onClick={() => setActiveTab(0)}
          className="features24-tab-horizontal1"
        >
          <div className="features24-divider-container1">
            {activeTab === 0 && <div className="features24-container2"></div>}
          </div>
          <div className="features24-content1">
            <h2>
              {props.feature1Title ?? (
                <Fragment>
                  <h2 className="features24-text5 thq-heading-2">
                    Partnership with Small Brands
                  </h2>
                </Fragment>
              )}
            </h2>
          </div>
        </div>
        <div
          onClick={() => setActiveTab(1)}
          className="features24-tab-horizontal2"
        >
          <div className="features24-divider-container2">
            {activeTab === 1 && <div className="features24-container3"></div>}
          </div>
          <div className="features24-content2">
            <h2>
              {props.feature2Title ?? (
                <Fragment>
                  <h2 className="features24-text4 thq-heading-2">
                    Retail Network Expansion
                  </h2>
                </Fragment>
              )}
            </h2>
          </div>
        </div>
        <div
          onClick={() => setActiveTab(2)}
          className="features24-tab-horizontal3"
        >
          <div className="features24-divider-container3">
            {activeTab === 2 && <div className="features24-container4"></div>}
          </div>
          <div className="features24-content3">
            <h2>
              {props.feature3Title ?? (
                <Fragment>
                  <h2 className="features24-text3 thq-heading-2">
                    Supply Chain Management
                  </h2>
                </Fragment>
              )}
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

Features24.defaultProps = {
  text: undefined,
  feature3Title: undefined,
  features24Id: '',
  feature2Title: undefined,
  feature1Title: undefined,
}

Features24.propTypes = {
  text: PropTypes.element,
  feature3Title: PropTypes.element,
  features24Id: PropTypes.string,
  feature2Title: PropTypes.element,
  feature1Title: PropTypes.element,
}

export default Features24
